:global(#app) {
  .editIcon {
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      color: var(--primary-color-);
    }
  }

  .header {
    position: sticky;
    top: 0;
  }

  .iconEdit {
    opacity: 0;
    margin-left: 5px;
  }

  .table {
    max-height: calc(100vh - 120px);
    height: fit-content;
    overflow-y: auto;
    padding: 0 1px;
    box-shadow: 0px 0px 5px 2px rgba(34, 36, 38, 0.1);
    border-radius: 3px;

    &::-webkit-scrollbar {
      width: 3px;
    }
  }

  @media screen and (max-width: 900px) {
    .table {
      max-height: calc(50vh - 120px);
    }
  }
}
