:global(#app) {
  .content {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.25);
    }

    & > div:first-child,
    & div:first-child > div[class*='ui grid'] {
      height: 100%;
    }
  }

  .item {
    align-items: center;
    display: flex;
    gap: 4px;
    padding: 0.8rem 1rem;
  }

  .wardItem {
    align-items: center;
    display: flex;
    gap: 4px;
    max-height: 32px;
    // width: fit-content;
  }

  @media screen and (max-width: 600px) {
    .content {
      div[class*='ui tabular menu'] {
        padding-bottom: 4px;
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          height: 3px;
        }
      }
    }
    .wardItem {
      width: 100%;
    }
  }
}
