:global(#app) {
  .field {
    margin-bottom: 8px;
  }

  .text {
    width: 100%;
    color: #444444;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 6px;
  }
}
