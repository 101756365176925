:global(#app) {
  .item {
    cursor: auto;
    user-select: auto;

    &:before {
      background: none;
    }

    &:active,
    &:hover {
      background: transparent;
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .itemHoverable:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.32);
  }

  .logo {
    display: flex;
    color: #fff;
    flex: 0 0 auto;
    // letter-spacing: 1.0px;
    line-height: 34px;
    padding: 8px 16px;
    // text-transform: uppercase;
    width: 190px;

    &:before {
      background: none;
    }

    img {
      margin-right: 2px;
    }
  }

  .menu {
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    flex: 1 1 auto;
    height: 50px;
    margin: 0;
    width: 100%;
    position: relative;
  }

  .notification {
    background: #eb5a46;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    height: 18px;
    line-height: 18px;
    min-width: 18px;
    position: absolute;
    right: 8px;
    text-align: center;
    top: 8px;
  }

  .time {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate((-50%, -50%));

    &::before {
      width: 0;
    }
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    margin-left: 20px;

    &:before {
      background: none;
    }
  }

  .triggerMobile {
    display: none;
  }

  .wrapper {
    background: #CCC; // rgba(0, 0, 0, 0.24);
    display: flex;
    flex: 0 0 auto;
  }

  .wrapperNotification {
    max-width: 100%;
    width: 500px;
  }

  .wrapperNotificationMobile {
    display: none;
  }

  @media screen and (max-width: 767px) {
    .time {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    .triggerMobile {
      background: transparent;
      border: none;
      display: unset;
      height: 100%;
      outline: none;
      position: absolute;
      right: 0px;
      width: 50px;
      z-index: 20;
    }

    .wrapperNotification {
      display: none !important;
    }

    .wrapperNotificationMobile {
      display: block !important;
      height: 100dvh;
      margin-bottom: 20px;
      margin-top: -15px;
      padding-bottom: 20px;
      overflow: hidden;
      width: 100%;

      & div[class^='header'] {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
}
