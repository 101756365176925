:global(#app) {
  .arrowElement {
    border-bottom: 5px solid #2185d0;
    border-left: 5px solid #2185d0;
    border-radius: 3px;
    height: 15px;
    width: 15px;
  }

  .arrowDown {
    @extend .arrowElement;
    animation: bounce 1.5s infinite;
    margin: 20px;
    position: relative;
    transform: rotate(-45deg);
    transform-origin: 10px 0px;

    &::after {
      @extend .arrowElement;
      content: '';
      left: -15px;
      position: absolute;
      top: 10px;
    }

    &::before {
      @extend .arrowElement;
      content: '';
      left: 5px;
      position: absolute;
      top: -10px;
    }
  }

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: rotate(-45deg) translateY(0);
    }
    50% {
      transform: rotate(-45deg) translate(10px, -10px);
    }
  }
}
