:global(#app) {
  .wrapper {
    margin-left: 70px;
    position: fixed;
    width: calc(100% - 70px);
    z-index: 100;
  }

  .sidebarMargin {
    margin-left: 260px;
    width: calc(100% - 260px);
  }

  @media only screen and (max-width: 1199px) {
    .wrapper {
      margin-left: 0px;
      width: 100%;
    }

    .sidebarMargin {
      margin-left: 50px;
      width: calc(100% - 50px);
    }
  }
}
