:global(#app) {
  .message {
    align-content: space-between;
    align-items: center;
    color: #fff;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
  }

  .messageIcon {
    margin-top: -84px;
  }

  .messageTitle {
    font-size: 32px;
    margin: 24px 0 8px;
  }

  .messageContent {
    font-size: 18px;
    line-height: 1.4;
    margin: 4px 0 0;
    text-align: center;
  }

  .wrapper {
    height: 100%;
    margin-top: 50px;
    margin-left: 70px;
    width: calc(100% - 70px);
  }

  .wrapperDistrict {
    margin-top: 174px;
  }

  .reduceMarginTop {
    margin-top: 124px !important;
  }

  .wrapperFlex {
    display: flex;
  }

  .wrapperLoader {
    position: relative;
  }

  .wrapperProvince {
    margin-top: 98px;
  }

  .sidebarMargin {
    margin-left: 260px;
    width: calc(100% - 260px);
  }

  @media only screen and (max-width: 1199px) {
    .wrapper {
      margin-left: 0px;
      width: 100%;
    }

    .sidebarMargin {
      margin-left: 50px;
      width: calc(100% - 50px);
    }
  }
}
