:global(#app) {
  .button {
    position: relative;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: inherit;
    background-color: transparent;
    box-shadow: unset;
    width: inherit;
    padding: 4px;

    & img {
      vertical-align: middle;
    }

    &:hover {
      background-color: #d4e9e266;
    }
  }

  .item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 6px;
    color: #1e3932;
    border-radius: 10px !important;

    &:nth-last-of-type(1) {
      color: #dc362e;
    }

    &:hover {
      background-color: #d4e9e266;
    }
  }

  .menu {
    position: absolute;
    top: 0px;
    left: 18px;
    transform: translateY(-100%);
    transition: all 0.2s ease;
    visibility: collapse;
    opacity: 0;
    padding: 8px;
    width: 220px;
    background-color: #f5f5f7;
  }

  .show {
    visibility: visible;
    opacity: 1;
    top: -5px;
  }

  .wrapper {
    position: absolute;
    bottom: 0px;
    padding: 4px 8px;
    width: 100%;
  }

  @media only screen and (max-width: 1199px) {
    .button {
      img {
        width: 30px !important;
        height: 30px !important;
      }
    }
    .iconUser {
      display: none;
    }

    .username {
      display: none;
    }

    .wrapper {
      padding: 4px;
    }
  }
}
