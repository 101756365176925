:global(#app) {
  .content {
    display: grid;
    height: 100%;
    grid-template-rows: 0.5fr 1fr;
  }

  .description {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    z-index: 1;

    span {
      font-weight: 700;
      font-size: 20px;
      color: #fff;
    }

    & svg {
      width: clamp(2rem, 5vw, 10rem);
      height: auto;
    }
  }

  .header {
    color: #fff;
    z-index: 1;
    font-size: clamp(12px, 3.5vw, 25px);
  }

  .wrapCard {
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    height: 150px;

    &::before {
      content: '';
      position: absolute;
      width: 96.74px;
      height: 62.59px;
      top: 0%;
      left: 0%;
      transform: translate(-1%, -1%);
      background: url('../../assets/icons/card/intersect-top.svg') center / contain no-repeat;
    }

    &::after {
      overflow: unset;
      visibility: visible;
      content: '';
      position: absolute;
      width: 162.83px;
      height: 122.66px;
      right: 0%;
      bottom: 0;
      transform: translate(1%, 1%);
      background: url('../../assets/icons/card/intersect.svg') center / contain no-repeat;
    }

    @media screen and (max-width: 600px) {
      height: 140px;
    }
  }
}
