:global(#app) {
  .actionCalendar {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .actionWrapper {
    display: flex;
    gap: 10px;
    justify-content: end;
    background: #f9fafb;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    margin: 20px -20px -20px -20px;
    padding: 10px 20px 10px 20px;
  }

  .buttonUpload {
    margin-bottom: 10px;
    display: flex;
    gap: 3px;
    padding: 6px;
  }

  .closeImage {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    z-index: 2;
    line-height: 12px;
    border: 5px solid #f5f5f7;
  }

  .colorButton {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 24px;
    height: 32px;

    &:hover {
      opacity: 0.9;
    }
  }

  .colorButtonActive {
    position: relative;
  }

  .colorButton:before {
    color: transparent;
    content: '';
    position: absolute;
    top: 0;
  }

  .colorButtonActive:before {
    top: 4px;
    color: #ffffff;
    content: 'Г';
    position: absolute;
    left: 8px;
    transform: rotate(-135deg);
  }

  .content {
    overflow-y: auto;
    max-height: 90svh;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
    }

    // Firefox
    scrollbar-width: thin;
  }

  .contentModule {
    margin-bottom: 16px;
  }

  .datePicker {
    position: relative;
    &::after {
      content: url(../../assets/icons/menu/schedule.svg);
      position: absolute !important;
      right: 5px;
      scale: 0.8;
    }
  }

  .inputMarginTop {
    margin-top: 2px;
  }

  .moduleWrapper {
    margin: 10px 0 10px 20px;
    position: relative;
  }

  .simplemde {
    margin-bottom: 10px;
  }

  .text {
    color: #6b808c;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin: 0 8px 4px 0;
    text-transform: uppercase;
  }

  .tip {
    font-style: italic;
  }

  .wrapper {
    width: 880px;
  }

  .wrapperCalendar {
    box-shadow: 0px 8px 16px 0px #00000026;
    padding: 10px 10px 50px;

    & button[aria-label='Next Month'],
    button[aria-label='Previous Month'] {
      top: 10px;
    }

    & div[class="react-datepicker__input-time-container"] {
      margin-left: 0;
    }
  }

  .wrapperImage {
    position: relative;
    width: fit-content;
    height: 250px;

    img {
      height: 100%;
      width: 100%;
      border-radius: 6px;
      box-shadow: 0px 2px 1px 6px #f5f5f7;
    }
  }

  @media (max-width: 926px) {
    .wrapper {
      width: 95%;
    }
  }
}
