:global(#app) {
  .action {
    background: var(--primary-color-);
    color: #fff;
    min-height: 16px;
    padding: 2px 5px;

    &:hover {
      opacity: 0.8;
    }
  }

  .actions {
    align-items: center;
    display: flex;
    margin: 20px 20px;
  }

  .buttonAdd,
  .buttonFilter {
    min-height: 38px;
    min-width: 50px;
    height: fit-content;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    color: #fff;
    font-size: clamp(0.8rem, 1.5vw, 1rem);

    i {
      display: none;
    }

    &::before {
      position: absolute;
      content: '';
      z-index: -1;
      left: 0;
      top: 0;
      background-color: var(--primary-color-);
      width: 100%;
      height: 100%;
      transition: all 0.2s ease-out;
    }

    &:hover {
      color: var(--primary-color-);
      background-color: #d4e9e2;

      &::before {
        width: 0px;
      }
    }
  }

  .buttonNavigate {
    @extend .buttonFilter;

    min-width: 100px;
  }

  .delete {
    background-color: #dc362e;
  }

  .searchBox {
    max-width: 20em;
    display: inline-block;
    height: fit-content;
  }

  .selectBox {
    height: fit-content;
    min-width: 80px;
    max-width: 400px;
  }

  .textBox {
    height: fit-content;
  }

  .wrapper {
    margin: 10px 10px 0px 10px;

    table {
      margin-bottom: 0;
    }
  }

  .wrapperFilter {
    display: flex;
    gap: 5px;
    flex: 0 0 90%;
    width: 100%;

    span {
      align-self: auto;
    }
  }

  .wrapperFilterAndAdd {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .wrapperPagination {
    background-color: rgba(223, 225, 224, 0.4);
    padding: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: right;
  }

  .wrapperTable {
    overflow-x: auto;
  }

  @media only screen and (max-width: 991px) {
    .selectBox,
    .searchBox {
      max-width: 100%;
    }

    .wrapperFilter {
      flex-direction: column;
      flex: 0 0 85%;
    }
  }

  @media only screen and (max-width: 599px) {
    .buttonAdd {
      margin-bottom: 5px;
    }

    .wrapperFilter {
      flex: 0 0 70%;
    }

    // .wrapperFilterAndAdd {
    //   flex-direction: column;
    //   gap: 10px;
    // }
  }
}
