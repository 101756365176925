:global(#app) {
  .audio {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 6px;
    margin: 20px 0;
    
    audio {
      height: 40px;
      width: 100%;
    }
  }

  .buttonCancel {
    background-color: transparent;
    color: #fff;
    font-size: clamp(0.8rem, 1.5vw, 1rem);
    height: fit-content;
    min-height: 38px;
    min-width: 50px;
    overflow: hidden;
    position: relative;
    width: 150px;

    i {
      display: none;
    }

    &::before {
      background-color: var(--material-theme-ref-error-error-50, #dc362e);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all 0.2s ease-out;
      width: 100%;
      z-index: -1;
    }

    &:hover {
      background-color: #d4e9e2;
      color: var(--material-theme-ref-error-error-50, #dc362e);

      &::before {
        width: 0px;
      }
    }
  }

  .buttonSave {
    background-color: transparent;
    color: #fff;
    font-size: clamp(0.8rem, 1.5vw, 1rem);
    height: fit-content;
    min-height: 38px;
    min-width: 50px;
    overflow: hidden;
    position: relative;
    width: 150px;

    i {
      display: none;
    }

    &::before {
      background-color: var(--primary-color-);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all 0.2s ease-out;
      width: 100%;
      z-index: -1;
    }

    &:hover {
      background-color: #d4e9e2;
      color: var(--primary-color-);

      &::before {
        width: 0px;
      }
    }
  }

  .content {
    background: none;
  }

  .countString {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
    a {
      background-color: unset;
      text-decoration: underline;
    }
  }

  .iconHeader {
    align-items: center;
    background: var(--base-white, #fff);
    border-radius: 10px;
    border: 1px solid var(--gray-200, #e4e7ec);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: inline-flex;
    height: clamp(26px, 2.5vw, 48px);
    justify-content: center;
    margin-right: clamp(4px, 2.5vw, 16px);
    width: clamp(26px, 2.5vw, 48px);
    i {
      font-size: 20px;
      line-height: 1;
      margin: 0;
    }
  }

  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    select {
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      background: #fff;
      width: 229px;
      height: 44px;
      padding: 6px 14px;
      box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.05);
    }
    label {
      color: var(--gray-700, #344054);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-right: 10px;
    }
  }

  .progress {
    margin-bottom: 20px;
    height: 20px;
    border-radius: 10px;
    > div {
      height: 20px;
      border-radius: 10px;
    }
  }

  .submitBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 20px 0 10px 0;
  }

  .title {
    color: var(--gray-900, #101828);
    font-size: clamp(14px, 2.5vw, 18px);
    font-weight: 600;
    line-height: 28px;
  }

  .textArea {
    width: 100%;
    height: 300px;
    background: #fff;
    border-radius: 5px;
    padding: 10px 16px;
    outline: none;
    border: 1px solid rgba(63, 67, 80, 0.16);
  }

  .wrapper {
    min-width: 800px;
    margin-top: 10px;
  }

  .wrapperOption {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 991px) {
    .wrapper {
      min-width: 95%;
    }

    .wrapperOption {
      flex-direction: column;
      row-gap: 18px;
    }
  }

  @media screen and (max-width: 599px) {
    .option {
      label {
        font-size: 14px;
      }
    }

    .submitBtn {
      justify-content: space-between;
      padding: 0 14px;
    }
  }
}
