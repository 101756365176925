:global(#app) {
    .centerButton {
        background-color: transparent;
        border-radius: 10px;
        color: #fff;
        display: flex;
        font-weight: 300;
        margin: auto;
        overflow: hidden;
        padding: 10px 20px;
        position: relative;
        text-align: center;
        z-index: 1;

        &::before {
            background-color: var(--primary-color-);
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: all 0.2s ease-out;
            width: 100%;
            z-index: -1;
        }

        &:hover {
            background-color: #d4e9e2;
            color: var(--primary-color-);
            &::before {
                width: 0px;
            }
        }
    }

    .wrapper {
        margin: 10px 10px 0 10px;
    }

    .wrapperUser {
        padding-left: 60px;
        padding-bottom: 30px;
    }
}
