:global(#app) {
  .actionWrapper {
    display: flex;
    gap: 10px;
    justify-content: end;
    background: #f9fafb;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    margin: 20px -20px -20px -20px;
    padding: 10px 20px 10px 20px;
  }

  .alertSchedule {
    left: 40%;
    position: fixed;
    text-align: center;
    top: 30%;
    z-index: 1000;
  }

  .nextButton {
    height: 38px;
  }

  .positionRelative {
    position: relative;
  }

  .wrapper {
    min-width: 95vw;
    transition: all 0.2s ease-in-out;
  }

  @media (max-width: 926px) {
    // .actionWrapper {
    //   margin: 20px -10px -14px -10px;
    // }

    .wrapper {
      // width: 95%;
      min-width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    .actionWrapper {
      margin: 20px -10px -10px -20px;
    }

    .positionRelative {
      div[class*='ui secondary menu'] {
        padding-bottom: 4px;
        overflow-x: auto;

        &::-webkit-scrollbar {
          height: 3px;
        }
      }
    }
  }
}
