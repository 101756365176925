:global(#app) {
  .wrapper {
    & > div:last-child {
      max-height: 150px !important;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }
}
