:global(#app) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    // height: 100%;
  }

  .count {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 0;

    span {
      font-size: 0.5em;
      color: #bcbcbc;
      margin-left: 8px;
    }
  }

  .footer {
    display: inline-flex;
    gap: 20px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .item {
    width: 100%;
  }

  .label {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  .progressBar {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 16px;
    background: #c7ece0;
  }

  /* Thumb: webkit */
  .progressBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    background-color: #00ba7b;
    border-radius: 50%;
    border: 2px solid #00ba7b;
    box-shadow: -407px 0 0 400px #00ba7b;
  }

  /* Thumb: Firefox */
  .progressBar::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #00ba7b;
    border-radius: 50%;
    border: 1px solid #00ba7b;
    box-shadow: -407px 0 0 400px #00ba7b;
  }

  .tickLabel {
    margin-top: 6px;
    width: 40px;
    height: 6px;
    border-radius: 4px;
    background: #c7ece0;
  }

  .tickLabelActive {
    background: #00ba7b;
  }

  .textLabel {
    p:first-child {
      color: #bcbcbc;
    }

    p {
      font-size: 0.9em;
    }
  }
  .title {
    font-weight: bold;
    font-size: 20px;
  }

  .wrapper {
    padding: 12px 30px;
    height: 100%;
    background: #f9f9f9;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  @media screen and (max-width: 599px) {
    .container {
      flex-direction: column;
    }

    .item {
      padding: 0 20px;
    }

    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .wrapper {
      padding: 10px;
      gap: 20px;
    }
  }
}
