:global(#app) {
  .actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }

  .button {
    position: relative;
    border-radius: 10px;
    width: 100%;
    background-color: var(--primary-color-);
    box-shadow: unset;
    overflow: hidden;
    transition: all 2s ease;
    transition-delay: 0s;
    font-weight: 600;
    font-size: 16px;

    & span {
      position: relative;
      z-index: 1;
    }

    &:nth-of-type(1) {
      background-color: #dc362e;
    }

    &::before {
      content: '';
      position: absolute;
      transition: all 1.2s ease;
      transition-delay: 0s;
      right: 0;
      bottom: 0;
      width: 5px;
      height: 5px;
      transform: scale(1) translate(100%, 40%);
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.8);
      opacity: 0;
    }

    &:hover {
      color: var(--primary-color-);

      &:nth-of-type(1) {
        color: #dc362e;
      }

      &::before {
        transform: scale(200);
        opacity: 1;
      }
    }
  }

  .borderDropdown {
    border-radius: 8px;
    max-height: 38px;
  }

  .error {
    height: 16px;
  }

  .errorContent {
    color: #cd3333;
    padding: 2px 14px;
    font-weight: bold;
    background-color: #ffeeee;
  }

  .errorMessage {
    & div[role='listbox'] {
      border-color: #e0b4b4;

      & div[class='message'] {
        color: #9f3a38;
      }
    }
  }

  h4 {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .field {
    & input {
      border-radius: 8px !important;
      border: 1px solid #d0d5dd;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    & i {
      width: 2rem;
    }
  }

  .position {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 5px;
  }

  .readOnly {
    & input {
      pointer-events: none;
    }
  }

  .text {
    color: #444444;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 6px;

    & sup {
      color: #ff3a31;
      vertical-align: middle;
    }
  }

  .topic {
    margin-bottom: 10px;
    font-weight: bold;
    color: var(--primary-color-);
  }

  .wrapperContent {
    overflow-y: scroll;
    height: calc(100vh - 120px);

    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  .wrapperField {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
  }

  .wrapperGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 14px;
  }

  @media screen and (max-width: 599px) {
    .wrapperContent {
      height: calc(100vh - 100px);
    }

    .wrapperGroup {
      grid-template-columns: auto;
    }
  }
}
