:global(#app) {
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  .wrapper {
    margin: 10px;
    margin-top: 0;
  }

  @media screen and (max-width: 900px) {
    .content {
      grid-template-columns: 1fr;
    }
  }
}
