:global(#app) {
  .content {
    // width: 500px;
  }

  .item {
    padding: 12px;
    position: relative;

    &:hover {
      background: #f0f0f0;

      .itemOptionButton {
        opacity: 1;
      }
    }
  }

  .itemButton {
    // background: transparent;
    box-shadow: none;
    float: right;
    height: 20px;
    line-height: 20px;
    margin: 0;
    min-height: auto;
    padding: 0;
    transition: background 0.3s ease;
    width: 20px;
    opacity: 0.45;

    &:hover {
      background: #e9e9e9;
    }

    &:disabled {
      opacity: 1 !important;
    }
  }

  .itemOptionButton {
    height: 30px;
    line-height: 30px;
    margin: 0;
    min-height: auto;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 40px;
    top: 12px;
    width: 30px;
  }

  .itemContent {
    display: inline-block;
    font-size: 12px;
    min-height: 36px;
    overflow: hidden;
    padding: 0 4px 0 8px;
    vertical-align: top;
    width: calc(100% - 56px);
    word-break: break-word;
  }

  .itemDeleted {
    display: inline-block;
    line-height: 20px;
    min-height: 20px;
    padding: 0 4px 0 8px;
    vertical-align: top;
    width: calc(100% - 20px);
  }

  .wrapper {
    margin: 0 -12px;
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      max-height: calc(100dvh - 150px);
    }
  }
}
