:global(#app) {
  .addTime {
    margin-left: 5px;
    padding: 5px;
    width: fit-content;
  }

  .border {
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 106px;
    padding: 5px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  .calendar {
    position: relative;
    border: 1px solid #ece9f1;
    border-radius: 8px;
    background-color: #fff;
    padding: 10px 10px;
    box-shadow: 0px 8px 16px 2px #00000026;

    button {
      top: 10px;
    }
  }

  .calendarWrapper {
    width: 100%;
  }

  .caption {
    margin-bottom: 10px;
  }

  .pickColor {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
    padding: 0;
    margin-top: 6px;

    input {
      visibility: hidden;
    }
  }

  .time {
    margin-bottom: 3px;
    width: fit-content;
  }

  .timeFrame {
    margin-bottom: 10px;
  }

  .wrapper {
    // height: 500px;
  }

  @media screen and (max-width: 767px) {
    .field {
      display: grid;
      grid-template-columns: 0.1fr 1fr;
    }

    .marginBottom {
      margin-bottom: 1em;
    }
  }

  @media (max-width: 599px) {
    .wrapper {
      left: 0 !important;
      top: 0 !important;
      width: 100%;
      height: 100%;
    }
  }
}
