:global(#app) {
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin-top: 20px;

    & button:nth-last-of-type(1) {
      background-color: #dc362e;
      color: #fff;
    }
  }

  .header {
    display: grid;
    grid-template-columns: auto 4fr;
    gap: 16px;

    & h4 {
      font-weight: bold;
    }

    & p {
      color: #7f7f7f;
      word-break: break-word;
    }
  }

  .wrapper {
    padding: 16px 8px 8px 8px;
    width: 400px;
  }

  @media screen and (max-width: 500px) {
    .wrapper {
      width: calc(100vw - 60px);
    }
  }
}
