:global(#app) {
  .addButton {
    position: absolute;
    bottom: 15px;
    right: 12px;
    width: 40px;
    height: 40px;
  }

  .wrapper {
    margin: 10px 10px 0 10px;
  }
}
