:global(#app) {
  .controls {
    display: flex;
    justify-content: space-between;
  }

  .createButton {
    white-space: nowrap;
  }

  .colorButton {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 24px;
    height: 20px;
    min-height: 20px;
    &:hover {
      opacity: 0.9;
    }
  }

  .field {
    margin-bottom: 8px;
  }

  @media screen and (max-width: 599px) {
    .wrapper {
      width: calc(100vw - 100px);
    }
  }
}
