:global(#app) {
    .buttonDeleteShow {
        padding: 5px 12px 3px;
    }
    .nameEditForm {
        width: fit-content;
        white-space: nowrap;
    }

    .draggableContainer {
        width: fit-content;
        margin-bottom: 10px;
    }

    details > summary::-webkit-details-marker {
        display: none;
    }

    .buttonStepHover {
        // opacity: 0;
        // visibility: visible;
        padding: 5px 12px 3px;
        position: absolute;
        right: -100px;
        bottom: 50%;
        transform: translateY(50%);
    }

    .colorButton {
        position: relative;
        overflow: hidden;
        display: inline-block;
        width: 24px;
        height: 20px;
        min-height: 20px;
        border-radius: 5%;
        &:hover {
            opacity: 0.9;
        }
    }

    .labelSummory {
        width: fit-content !important;
        display: flex;
        justify-content: space-between;
        gap: 5px;
        align-items: center;
        width: fit-content;
        z-index: 2;
        transition: all 0.2s ease-in-out;
        &:hover {
            .icon {
                opacity: 0.8;
                margin-left: 0;
                padding-left: 5px;
                width: 13px;
                transition: all 0.2s ease-in-out;
            }
        }
    }
    .labelCreate {
        margin-left: 35px;
    }
    .icon {
        margin: 0;
        margin-left: -5px;
        overflow: hidden;
        width: 0px;
        font-size: 0.92857143em;
        transition: all 0.2s ease-in-out;
    }
}
