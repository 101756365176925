:global(#app) {
  .buttonIcon {
    line-height: 10px;
    background-color: var(--primary-color-);
  }

  .delete {
    background-color: #dc362e;
  }

  .fieldButton {
    background-color: transparent;
    color: #fff;
    height: 38px;
    position: relative;
    width: fit-content;
    vertical-align: bottom;

    &::before {
      content: '';
      position: absolute;
      transition: width 0.2s ease-in-out;
      left: 0;
      top: 0;
      background-color: var(--primary-color-);
      z-index: -1;
      width: 100%;
      border-radius: inherit;
      height: 100%;
    }

    &:hover {
      background-color: #eee;
      color: var(--primary-color-);

      &::before {
        width: 0%;
      }
    }
  }

  .fieldButtonAdd {
    @extend .fieldButton;
  }

  .filters {
    display: flex;
    gap: 6px;
    width: fit-content;
  }

  .input {
    height: fit-content;
    min-height: 38px;
    min-width: 200px;
  }

  .pagination {
    text-align: right;
  }

  .wrapper {
    margin: 10px;
  }

  .wrapperFilterAdd {
    display: flex;
    gap: 6px;
    justify-content: space-between;
  }

  .wrapperTable {
    margin-top: 20px;
    padding-bottom: 10px;
    overflow-x: auto;

    table {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 899px) {
  .filters {
    flex-direction: column;
  }
}
