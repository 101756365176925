:global(#app) {
  .active {
    background-color: rgba(223, 249, 186, 0.3) !important;

    & svg>path,
    span,
    i {
      color: var(--primary-color-);
      stroke: var(--primary-color-);
    }
  }

  .condensed {
    width: 70px !important;
  }

  .condensedButton {
    position: absolute;
    right: 0;
    top: 50px;
    transform: translate(50%, 0%);
    z-index: 20;
    padding: 0px;
    width: 24px;
    min-height: 24px;
    background-color: #1e3932;
    color: #fff;
  }

  .chevron {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(50%);
  }

  .item {
    display: flex;
    gap: 4px;
    height: 40px;
    padding: 8px;
    line-height: 2;
    align-items: center;
    cursor: pointer;

    & svg {
      width: 35px !important;
      height: 35px !important;
    }

    & span {
      height: fit-content;
      white-space: nowrap;
      overflow: hidden;
      font-size: 16px;
    }

    & i {
      margin-left: auto;
      margin-bottom: auto;
    }

    &:hover {
      background-color: rgba(212, 233, 226, 0.4);

      & svg>path,
      span {
        color: var(--primary-color-);
        stroke: var(--primary-color-);
      }
    }
  }

  .logo,
  .logoMobile {
    display: flex;
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;
    align-items: center;

    &::before {
      height: 0px;
    }
  }

  .logoMobile {
    padding: 8px;
    display: none;
  }

  .marginLeft {
    margin: auto;
  }

  .wrapper {
    margin-top: 50px;
    z-index: 900;
    width: 260px;
    transition: all 0.2s ease;
    overflow: visible !important;
  }

  @media only screen and (max-width: 1199px) {
    .condensed {
      width: 0px !important;
    }

    .hidden {
      display: none;
    }

    .position {
      right: -20px;
      top: 15px;
    }

    .item {
      // padding: 2px;

      >span {
        display: none;
      }
    }

    .itemPadding {
      padding: 0px !important;
    }

    .logo {
      display: none;
    }

    .logoMobile {
      display: flex;
    }

    .wrapper {
      width: 50px;
    }
  }
}
