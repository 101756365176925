:global(#app) {
  .calendar {
    box-shadow: 0 0 2px 2px #ccc;
  }

  .buttonFilter {
    background-color: var(--primary-color-);
    color: #fff;
  }

  .label {
    align-self: top;
    font-weight: bold;
    margin-right: 8px;
    vertical-align: middle;
  }

  .table {
    th {
      color: var(--primary-color-);
      background-color: #f2f8f6;
    }
  }

  .wrapper {
    margin: 10px 10px 0 10px;
  }

  .wrapperDatePicker {
    margin-right: 20px;
    position: relative;
    width: 200px;

    input {
      border-width: 1px;
      border-radius: 6px;
      border-color: #eaeaea;
      outline: none;
      padding: 8px;
      position: relative;
      width: 100%;

      &:focus {
        border-color: var(--primary-color-);
      }
    }

    &::after {
      content: url(../../assets/icons/menu/schedule.svg);
      position: absolute;
      right: 0px;
      top: 0px;
      transform: scale(0.8);
    }
  }

  .wrapperFilter {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 10px;

    i {
      align-self: top;
    }
  }

  /* Mobile */

  @media screen and (max-width: 599px) {
    .buttonFilter {
      margin-top: 10px;
    }

    .wrapperDatePicker {
      margin-right: 0;
      margin-bottom: 10px;
      width: 100% !important;
    }

    .wrapperFilter {
      flex-direction: column;
      align-items: stretch;
    }
  }
}
