:global(#app) {
  .content {
    max-height: 80svh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  .item {
    align-items: center;
    display: flex;
    gap: 4px;
    padding: 0.5rem 1rem;
  }

  .wrapper {
    margin-top: 15px;
    width: 880px;
  }
}
