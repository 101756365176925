:global(#app) {
  .calendar {
    position: relative;
    border: 1px solid #ece9f1;
    border-radius: 8px;
    background-color: #fff;
    padding: 10px 10px;
    box-shadow: 0px 8px 16px 2px #00000026;

    button {
      top: 10px;
    }
  }

  .calendarWrapper {
    width: 100%;
  }
}
