:global(#app) {
  $bgGray: rgb(237, 235, 235);

  @mixin inputNumber {
    input {
      background-color: $bgGray;
      max-width: 50px !important;
      -webkit-appearance: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }

  .active {
    background-color: #24a9e8 !important;
    color: #fff;
  }

  .action {
    background: none;
    box-shadow: none;

    &:hover {
      background: rgb(242, 245, 246);
    }
  }

  .calendar {
    border: 1px solid rgba(29, 30, 31, 0.15);
    padding: 10px;

    button {
      top: 10px;
    }
  }

  .dayOfWeek {
    background-color: $bgGray;
    margin-right: 6px;
    min-height: 30px;
    padding: 2px 8px 0px;
  }

  .datePicker {
    background-color: $bgGray;
    border-radius: 3px;
    border: 1px solid rgba(29, 30, 31, 0.15);
    line-height: 20px;
    outline: none;
    padding: 8px 12px;
    width: 140px;
  }

  .disabled {
    opacity: 0.8;
    pointer-events: none;
  }

  .itemCheck {
    margin-top: 10px;
    align-items: center;
    display: grid;
    grid-template-columns: 100px auto;
  }

  .itemCheckInput {
    background-color: $bgGray;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 3px;
    padding-right: 12px;
    width: fit-content;

    input {
      border: none;
    }
    @include inputNumber();
  }

  .itemEvery {
    align-items: center;
    display: flex;
    gap: 8px;
    @include inputNumber();
  }

  .marginTop {
    margin-top: 16px;
  }

  .selectEvery {
    background-color: $bgGray;
    min-width: 90px;
  }

  .selectMonth {
    @extend .marginTop;
    background-color: $bgGray;
    line-height: 20px;
    padding: 8px 12px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 3px;
  }

  .wrapperDayOfWeek {
    margin-top: 6px;
  }

  .wrapperActions {
    @extend .marginTop;
    text-align: right;

    button:nth-last-child(1) {
      color: #24a9e8;
    }
  }
}
