:global(#app) {
  .detail {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 20px;
  }

  .field {
    display: grid;
    grid-template-columns: 160px 1fr;
    margin-bottom: 20px;
  }

  .fitContent {
    width: fit-content;
  }

  .item {
    box-shadow: rgba(14, 47, 102, 0.25) 0px 4px 8px -2px, rgba(17, 61, 139, 0.08) 0px 0px 0px 1px;
    border-radius: 4px;
    border: 1px solid rgb(189, 205, 228);
    height: fit-content;
    margin-right: 4px;
    padding: 4px 8px;
    width: fit-content;
  }

  .name {
    font-weight: bold;
  }

  .row {
    display: grid;
    grid-template-columns: 200px 1fr;
    margin-bottom: 14px;
  }

  .wrapperDetail {
    border: 1px solid rgb(200, 196, 196);
    border-radius: 4px;
    padding: 16px;
  }

  @media screen and (max-width: 600px) {
    .detail {
      grid-template-columns: 1fr;
      gap: 15px;
    }

    .gridHorizontal {
      gap: 10px;
      grid-template-columns: 1fr;
    }

    .wrapper {
      margin-top: -14px;
      height: 100%;
      width: 100%;
    }

    .wrapperDetail {
      height: inherit;
      height: calc(100svh - 335px);
      overflow-y: auto;
    }
  }
}
