:global(#app) {
  .title {
    padding: 0 0 4px;
  }

  .wrapper {
    height: fit-content;
    background-color: #ddeae6;
    border: unset;
    margin: 0;
  }

  @media screen and (max-width: 900px) {
    .wrapper {
      order: 2;
    }
  }
}
