:global(#app) {
  .marginWrapHome {
    margin: 0 16px;
  }

  .wrapper {
    height: 100%;
    display: grid;
    // row-gap: 20px;
    grid-template-rows: auto 2fr;
  }

  @media screen and (max-width: 600px) {
    .marginWrapHome {
      margin: 0 10px;
    }

    .wrapper {
      grid-template-rows: 1fr 3fr;
    }
  }
}
