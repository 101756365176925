:global(#app) {
  .actions {
    position: absolute;
    top: 0;
    right: 0;

    button {
      width: 40px;
      height: 40px;
      background: none;
      box-shadow: none;
    }
  }

  .actionWrapper {
    display: flex;
    gap: 10px;
    justify-content: end;
    background: #f9fafb;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    margin: 10px -10px -10px -10px;
    padding: 5px 7px;
  }

  .attachment {
    margin: 0px 6px 6px 0;
  }

  .cursorPointer {
    cursor: pointer;
  }

  .header {
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
    height: 30px;
  }

  .playing {
    color: var(--primary-color-);
    animation: playing 1s ease-in-out infinite;
    opacity: 1;

    @keyframes playing {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  .rowItem {
    display: grid;
    grid-template-columns: 24px 1fr;
    margin-bottom: 10px;

    span {
      color: #44474a;
    }

    div>span {
      margin-left: 15px;
    }
  }

  .wrapper {
    overflow: hidden;
  }
}
