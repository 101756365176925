:global(#app) {
  .container {
    display: flex;
    justify-content: space-between;
    // grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    height: fit-content;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .item {
    width: 100%;
  }

  .noted {
    overflow-y: auto;

    &:hover {
      &::-webkit-scrollbar-track {
        background-color: #e7e5e5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #b2b2b2;
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    .label {
      font-size: 11px;
      font-weight: 600;

      .box {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
    }
  }

  .statistic {
    align-content: space-between;
    border: 5px solid #70dcb8;
    border-radius: 8px;
    box-shadow: 0 2px 5px #bcbcbc;
    flex: 1 1 100%;
    padding: 6px 12px;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: translateY(-5%);
    }
  }

  .title {
    font-weight: bold;
    font-size: 20px;
  }

  .wrapper {
    padding: 12px 30px;
    height: 100%;
    background: #f9f9f9;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .wrapperChart {
    height: clamp(200px, 20vw, 350px);
    display: flex;
    gap: 30px;
    justify-content: center;
  }

  @media screen and (max-width: 599px) {
    .container {
      flex-direction: column;
      padding: 0 30px;
    }

    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .wrapper {
      padding: 10px;
      gap: 10px;
    }

    .wrapperChart {
      flex-direction: column;
      max-height: 400px;
      height: 100%;
      width: 250px;
      padding-top: 20px;
      margin: auto;
    }
  }
}
