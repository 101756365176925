:global(#app) {
  .actionWrapper {
    display: flex;
    gap: 10px;
    justify-content: end;
    background: #f9fafb;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    margin: 20px -20px -20px -20px;
    padding: 10px 20px 10px 20px;
  }

  .borderDropdown {
    border-radius: 8px;
    max-height: 38px;
  }

  .field {
    & input {
      border-radius: 8px !important;
      border: 1px solid #d0d5dd;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    & i {
      width: 2rem;
    }
  }

  .fieldParameter {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    margin-bottom: 10px;
  }

  .text {
    color: #444444;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 6px;

    & sup {
      color: #ff3a31;
      vertical-align: middle;
    }
  }

  .wrapperField {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
  }

  .wrapperGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 600px) {
    .actionWrapper {
      margin: 20px -10px -10px -10px;
    }

    .wrapperGroup {
      grid-template-columns: 1fr;
    }
  }
}
