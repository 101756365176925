:global(#app) {
  .controls {
    display: flex;
    max-width: 280px;
    margin-top: 15px;
    @media only screen and (max-width: 767px) {
      max-width: 226px;
    }
  }

  .createButton {
    white-space: nowrap;
  }

  .colorButton {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 24px;
    height: 20px;
    min-height: 20px;
    &:hover {
      opacity: 0.9;
    }
  }

  .field {
    margin-bottom: 8px;
  }

  .importButton {
    background: transparent;
    box-shadow: none;
    color: #6b808c;
    font-weight: normal;
    margin-left: auto;
    margin-right: 0;
    overflow: hidden;
    text-align: left;
    text-decoration: underline;
    text-overflow: ellipsis;
    transition: none;
    white-space: nowrap;

    &:hover {
      background: rgba(9, 30, 66, 0.08);
      color: #092d42;
    }
  }

  .importButtonIcon {
    text-decoration: none;
    min-height: 20px;
  }

  .colorButtonActive {
    position: relative;
  }

  .colorButton:before {
    color: transparent;
    content: "";
    position: absolute;
    top: 0;
  }
  .colorButtonActive:before {
    top: 0px;
    color: #ffffff;
    content: "Г";
    position: absolute;
    left: 9px;
    transform: rotate(-135deg);
  }

  .text {
    color: #6b808c;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin: 0 8px 4px 0;
    text-transform: uppercase;
  }
}
