:global(#app) {
  .active {
    opacity: 1 !important;
  }

  .button {
    button {
      background-color: var(--primary-color-);
      color: #fff;
      width: 100%;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .buttonAuthType {
    height: 48px;
    margin-bottom: 10px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .cover {
    background: url('../../assets/images/login-cover.png') center / cover;
  }

  .descriptionSubtitle {
    font-size: 24px;
    font-weight: normal;
    margin-top: 8px;
  }

  .descriptionTitle {
    font-size: 52px;
    margin-bottom: 8px;
  }

  .descriptionWrapper {
    padding: 60px 0 0 80px;
    position: relative;
  }

  .descriptionWrapperOverlay {
    background: rgba(33, 33, 33, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .formTitle {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 32px;
    color: var(--primary-color-);
  }

  .fullHeight {
    height: 100%;
  }

  .fullHeightPaddingFix {
    height: calc(100% + 1rem);
  }

  .inputLabel {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .inputWrapper {
    margin-bottom: 16px;
  }

  .loginWrapper {
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    transition: all 0.2s ease-out;
  }

  .transition {
    display: grid;
    grid-template-columns: 100% 100% 100%;
    overflow: hidden;
    max-width: 440px;
    margin: auto;
    place-items: center;
  }

  .toLeft {
    transform: translateX(-100%);
  }

  .wrapper {
    background: #fff;
  }

  @media screen and (max-width: 991px) {
    .fullHeight {
      height: 40svh;
    }

    .loginWrapper {
      padding: 0;
    }
  }
}
