:global(#app) {
  .active {
    background-color: var(--primary-color-) !important;
    opacity: 1 !important;
    color: #fff;
  }

  .track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-height: 26px;
    margin-block: 2px;
    background-color: transparent;
    box-shadow: unset;
    opacity: 0.8;

    p {
      margin: auto 0;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover {
      background-color: rgba(223, 249, 186, 0.6);
      opacity: 1;
    }
  }
}
