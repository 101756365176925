:global(#app) {
  @mixin scrollBox($height) {
    max-height: $height;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  .category {
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    padding: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .containerNotification {
    position: absolute;
    right: 10px;
    width: fit-content;
  }

  .containerTime {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .field {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    margin-bottom: 10px;
    padding: 5px 0;
  }

  .fieldName {
    font-size: 16px;
    font-weight: bold;
  }

  .header {
    font-size: clamp(16px, 2.5vw, 20px);
  }

  .labelWard {
    margin-bottom: 4px;
  }

  .wrapperWard {
    @include scrollBox(150px);
  }

  @media screen and (max-width: 600px) {
    .field {
      grid-template-columns: 1fr;
    }

    .wrapper {
      margin-top: -1rem;
      width: 100%;
      height: 100svh;
    }
  }
}
