:global(#app) {
  .attachmentMarginTop {
    margin-top: 10px;
  }

  .field {
    margin-bottom: 10px;
  }

  .label {
    font-size: 13px;
    font-weight: bold;
    margin: 0 0 4px;
  }

  .moduleIcon {
    color: #17394d;
    font-size: 17px;
    height: 32px;
    left: -40px;
    line-height: 32px;
    margin-right: 0;
    position: absolute;
    top: 2px;
    width: 32px;
  }

  .moduleWrapper {
    margin: 10px 0 0 40px;
    position: relative;
    display: flex;
    align-items: start;
    gap: 10px;

    img {
      height: 50px;
    }
  }

  .recordPopupBtn {
    padding-top: 0;
    padding-bottom: 0;
  }

  .text {
    color: #6b808c;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin: 0 8px 4px 0;
    text-transform: uppercase;
  }

  .text2SpeechWrapper {
    align-items: center;
    display: flex;
    gap: 5px;
  }

  .tip {
    font-style: italic;
  }

  .wrapperCreateContent {
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: 1199px) {
    .tip {
      display: none;
    }
  }

  @media (max-width: 926px) {
    .actionWrapper {
      margin: 20px -10px -14px -10px;
    }

    .wrapper {
      width: 95%;
    }
  }
}
