:global(#app) {
  details {
    cursor: pointer;
  }

  details > summary {
    list-style: none;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }

  .tree {
    --spacing: 31px;
    --radius: 8px;
    margin: 0;
    padding-left: 0;
    // margin-bottom: 10px;
    list-style: none;
    position: relative;
  }

  .tree li {
    display: block;
    position: relative;
    padding-left: calc(2 * var(--spacing) - var(--radius) - -5px);
    transition: all 0.3s ease-in-out;
  }

  .tree li::after,
  .tree summary::before {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 27px;
    // background: #ddd;
  }

  .tree summary {
    transform: all 0.3s ease-in-out;
    cursor: pointer;
  }

  .tree summary:focus {
    outline: none;
  }

  .tree summary:focus-visible {
    outline: 1px dotted #000;
  }

  .tree ul {
    margin-left: calc(var(--radius) - var(--spacing));
    padding-left: 0;
    display: inline-block;
    line-height: 1;
  }

  .tree ul li:last-child {
    border-color: transparent;
  }

  .tree summary::before {
    z-index: 1;
    // background: #006241;
  }

  .tree details > summary::before {
    content: "-";
    text-align: center;
    // font-size: 10px;
    color: black;
    font-weight: bold;
  }

  .tree details[open] > summary::before {
    background-position: calc(-2 * var(--radius)) 0;
    content: "+";
    overflow: hidden;
    text-align: center;
    color: black;
    font-weight: bold;
  }

  .tree details[open]::before {
    border-left: 1px dotted gray;
    position: absolute;
    left: 30px;
    content: "";
    bottom: -13px;
    z-index: 2;
    height: 100%;
    width: 1px;
  }

  .icon {
    margin: 0;
    // margin-left: 10px;
  }

  .labelSummory {
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    width: fit-content;
    z-index: 2;
    transition: all 0.2s ease-in-out;

    &:hover {
      .icon {
        opacity: 0.8;
        width: 13px;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .labelCreate {
    margin-top: 5px;
  }

  .wrapper {
    border: none;
    box-shadow: none;
  }
}
