:global(#app) {
  .customIconSpeakerRed {
    filter: hue-rotate(0deg);
  }

  .customIconSpeakerYellow {
    filter: hue-rotate(60deg) saturate(2) brightness(1.7);
  }

  .customIconSpeakerBlue {
    filter: hue-rotate(225deg);
  }

  .customIconSpeakerGrey {
    filter: saturate(0%);
  }

  .customIconDisplayRed {
    filter: hue-rotate(165deg) saturate(3) brightness(1.2);
  }

  .customIconDisplayYellow {
    filter: hue-rotate(205deg) saturate(3) brightness(1.6);
  }

  .customIconDisplayBlue {
    filter: hue-rotate(0deg) saturate(1) brightness(1);
  }

  .customIconDisplayGrey {
    filter: saturate(0%);
  }
}
