:global(#app) {
  .actionWrapper {
    display: flex;
    gap: 10px;
    justify-content: end;
    background: #f9fafb;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    margin: 10px -10px -10px -10px;
    padding: 5px 7px;
  }

  .content {
    color: #212121;
    padding-bottom: 6px;
    padding-left: 2px;
  }

  .labelMobile {
    display: none;
  }

  .fieldTime {
    margin: 0 0em;
  }

  .pickColor {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
    padding: 0;
    margin-top: 6px;

    input {
      visibility: hidden;
    }
  }

  @media screen and (max-width: 767px) {
    .field {
      display: grid;
      grid-template-columns: 0.1fr 1fr;
    }

    .labelMobile {
      display: block;
    }

    .marginBottom {
      margin-bottom: 1em;
    }
  }
}
