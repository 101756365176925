:global(#app) {
  .calendar {
    border: 1px solid #d6d6d6;
    font-size: 16px;
    padding: 6px 6px;
  }

  .marginBottom {
    margin-bottom: 14px;
  }

  .messageNonMarginTop {
    margin-top: 0;
  }
}
