:global(#app) {
  .comboBox {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 3px;
    cursor: text;
    position: relative;

    &:active,
    &:focus-within {
      border-color: #85b7d9 !important;
    }
  }

  .dropdown {
    position: absolute;
    right: 8px;
    top: 10px;
    font-size: 0.85em;
  }

  .input {
    border: none;
    max-width: 250px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .label {
    margin: 0 0 4px;
    font-size: 13px;
    font-weight: bold;
  }

  .labelSelected {
    border: 1px solid #ccc;
    margin-inline: 4px;
    margin-top: 4px;
    padding: 5px 11px;
    text-transform: capitalize;
  }

  .results {
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: translateY(100%);
    z-index: 10;
    width: 100%;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #85b7d9;
    border-radius: 3px;
    max-height: 240px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: inherit;
    }
  }

  .searchItem {
    text-transform: capitalize;
    color: #1e3932;
    padding: 6px 4px;
    border-radius: 3px;
    font-weight: bold;

    &:hover {
      background-color: #d4e9e2;
    }
  }

  .wrapper {
    position: relative;
  }
}
