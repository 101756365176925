:global(#app) {
  $background-gray: #ddeae6;

  .actions {
    display: flex;
    gap: 5px;
  }

  .button {
    box-shadow: -5px -5px 15px 0px #ffffff9e, 5px 5px 10px 0px rgba(163, 177, 198, 0.6);
    background: $background-gray;
    border-radius: 2em;
    border: 0;

    &:active {
      box-shadow: inset -5px -5px 15px 0px #ffffff9e,
        inset 5px 5px 10px 0px rgba(163, 177, 198, 0.6);
    }
  }

  .currentTime {
    margin-left: 8px;
    margin-block: auto;
  }

  .volume {
    display: flex;
    align-items: center;

    button {
      background: none;
      box-shadow: unset;
    }

    input[type='range'] {
      cursor: pointer;
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;

    button {
      line-height: 14px;
    }
  }

  @media screen and (max-width: 599px) {
    .wrapper {
      flex-direction: column;
      gap: 10px;
    }
  }
}
