:global(#app) {
  @mixin scrollBox($height) {
    max-height: $height;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  .category {
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    padding: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .content {
    padding: 20px;
  }

  .field {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    margin-bottom: 10px;
    padding: 5px 0;
  }

  .fieldName {
    font-size: 16px;
    font-weight: bold;
  }

  .hiddenIconClose {
    i {
      display: none;
    }
  }

  .labelWard {
    margin-bottom: 4px;
  }

  .listCategories {
    @include scrollBox(110px);
  }

  .listLocation {
    @include scrollBox(118px);
  }

  .listTime {
    @include scrollBox(280px);
  }

  .marginBottomFive {
    margin-bottom: 5px;
  }

  .marginRightTen {
    margin-right: 10px;
  }

  .selectedLevel {
    width: 120px;
  }

  .wrapper {
    div[class*='header'] {
      font-size: clamp(16px, 2.5vw, 20px);
    }
  }

  @media screen and (max-width: 600px) {
    .field {
      grid-template-columns: 1fr;
      gap: 5px;
    }

    .wrapper {
      width: 100%;
    }
  }
}
