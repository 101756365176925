:global(#app) {
  .button {
    background-color: var(--primary-color-);
    color: #fff;
    margin-bottom: 10px;
    width: 100%;

    &:hover {
      opacity: 0.7;
    }
  }

  .counter {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 4px solid var(--primary-color-);
    display: grid;
    place-items: center;
    margin: auto;
    animation: counter 2s infinite ease-in-out;
    box-shadow: 0px 0px 10px rgb(37, 158, 37);
    transition: all 0.2 ease-out;
    overflow: hidden;
  }

  .formTitle {
    color: var(--primary-color-);
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 32px;
    text-align: center;
  }

  .hidden {
    height: 0px;
    box-shadow: none;
    border: none;
  }

  .notice {
    font-size: 12px;
  }

  .inputLabel {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .inputWrapper {
    margin-bottom: 16px;
  }

  .marginLeftButton {
    margin-left: 10px;
  }

  @media screen and (max-width: 991px) {
    .marginLeftButton {
      margin-left: 0px;
      margin-top: 10px;
      width: 100%;
    }
  }
}
