:global(#app) {
  .audioControls {
    align-items: flex-end;
    display: flex;
    gap: 8px;
    margin-bottom: 20px;

    audio {
      height: 40px;
      margin-bottom: 1em;
    }
  }

  .caption {
    display: flex;
    justify-content: space-between;
    margin-block: 16px;
  }

  .contentWrapper {
  }

  .containerDragDrop {
    margin-bottom: 0.7rem;
    max-height: calc(100dvh - 300px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .delete {
    background-color: transparent;
    box-shadow: unset;
    border: 1px solid transparent;
    padding: 4px;

    &:hover {
      border: 1px solid red;
      color: red;
    }
  }

  .dragActions {
    position: absolute;
  }

  .header {
    align-items: center;
    border-bottom: 1px solid #e4e7ec;
    display: flex;
    gap: 10px;
    padding: 14px 16px;
  }

  .save {
    background-color: #00ad73;
    color: #fff;
  }

  .table {
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.3rem;
    border-collapse: separate;
    border-spacing: 0;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.87);
    margin: 1em 0;
    text-align: left;
    width: 100%;
    -webkit-box-shadow: none;

    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-top: 0;
    }

    thead th {
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
      border-left: none;
      font-style: none;
      font-weight: 700;
      padding: 0.9em 0.7em;
      text-transform: none;
      text-align: inherit;
      vertical-align: inherit;
    }

    thead tr > th:first-child {
      border-left: none;
    }

    thead tr:first-child > th:first-child {
      border-radius: 0.3rem 0 0 0;
    }

    thead tr:first-child > th:last-child {
      border-radius: 0 0.3rem 0 0;
    }

    thead tr:first-child > th:only-child {
      border-radius: 0.3rem 0.3rem 0 0;
    }

    tr td {
      border-top: 1px solid rgba(34, 36, 38, 0.1);
    }

    tr:first-child td {
      border-top: none;
    }

    tbody + tbody tr:first-child td {
      border-top: 1px solid rgba(34, 36, 38, 0.1);
    }

    td {
      padding: 0.8em 0.8em;
      text-align: inherit;
    }

    tr {
      grid-template-columns: 0.1fr 0.4fr 0.2fr 0.2fr 0.1fr;
    }
  }

  .tableHeader {
    th {
      background-color: #f2f8f6;
      color: var(--primary-color-);
    }
  }

  .upload {
    background-color: #00ad73;
    color: #fff;
    padding: 2px 8px;
  }

  .wrapper {
    margin-top: -10px;
  }

  .wrapperIcon {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 4px 6px;
    width: fit-content;

    i {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .table {
      thead {
        th {
          border-bottom: 0px;
          display: block !important;
          width: 100% !important;
        }
      }

      tbody {
        td {
          border-top: 0px;
          display: block !important;
          padding: 0.6em;
          width: 100% !important;
        }
      }

      tr {
        box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1) inset !important;
        grid-template-columns: 1fr;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .caption {
      flex-direction: column;
    }

    .wrapper {
      width: 100%;
    }
  }
}
