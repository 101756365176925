$priColor: var(--primary-color-);

:global(#app) {
  .pointer::before {
    left: 3em;
  }

  .slider {
    -webkit-appearance: none;
    position: relative;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    height: 6px;
    background: #ccc;
  }

  .slider::before {
    position: absolute;
    content: '';
    border-radius: 15px;
    width: 100%;
    height: 100%;
    background-color: $priColor;
    transform: scaleX(var(--progress-width));
    transform-origin: left;
    z-index: 3;
  }

  .slider::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #769a8e;
    transform: scaleX(var(--buffered-width));
    transform-origin: left;
  }

  .slider::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    height: 16px;
    width: 16px;
    background-color: $priColor;
    border-radius: 50%;
    border: none;
    transition: 0.2s ease-in-out;
    z-index: 5;
  }

  .slider::-moz-range-thumb {
    position: relative;
    height: 16px;
    width: 16px;
    background-color: $priColor;
    border-radius: 50%;
    border: none;
    transition: 0.2s ease-in-out;
    z-index: 5;
  }

  .slider::-webkit-slider-thumb:hover {
    box-shadow: 0 0 0 10px rgba(0, 108, 76, 0.1);
  }
  .slider:active::-webkit-slider-thumb {
    box-shadow: 0 0 0 13px rgba(0, 108, 76, 0.2);
  }
  .slider:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 13px rgba(0, 108, 76, 0.2);
  }

  .slider::-moz-range-thumb:hover {
    box-shadow: 0 0 0 10px rgba(0, 108, 76, 0.1);
  }
  .slider:active::-moz-range-thumb {
    box-shadow: 0 0 0 13px rgba(0, 108, 76, 0.2);
  }
  .slider:focus::-moz-range-thumb {
    box-shadow: 0 0 0 13px rgba(0, 108, 76, 0.2);
  }

  .wrapper {
    margin-bottom: 8px;
  }
}
