:global(#app) {
  .actionWrapper {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    background: #f9fafb;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    margin: 10px -10px -10px -10px;
    padding: 5px 7px;
    border-bottom-left-radius: 5px;
  }

  .cursorPointer {
    cursor: pointer;
  }

  .fieldTime {
    margin: 0 0em;
  }

  .header {
    // color: #6b808c;
    font-size: 14px;
    // font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    border-bottom: 1px solid #eee;
    height: 30px;

    button {
      background: none;
      box-shadow: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
    }
  }

  .labelMobile {
    display: none;
  }

  .paddingEditModal {
    padding-top: 0px !important;
  }

  .pickColor {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
    padding: 0;
    margin-top: 6px;

    input {
      visibility: hidden;
    }
  }

  .wrapper {
    width: 400px;
    position: absolute;
    z-index: 999;
    background: white;
    border-radius: 5px;
    box-shadow: 0 8px 16px -4px rgba(9, 45, 66, 0.25), 0 0 0 1px rgba(9, 45, 66, 0.08) !important;
    padding: 10px;
    transition: all 0.1s ease;
    // overflow: hidden;
  }

  @media screen and (max-width: 767px) {
    .field {
      display: grid;
      grid-template-columns: 0.1fr 1fr;
    }

    .labelMobile {
      display: block;
    }

    .marginBottom {
      margin-bottom: 1em;
    }
  }

  @media (max-width: 599px) {
    .wrapper {
      left: 0 !important;
      top: 0 !important;
      width: 100%;
      height: 100%;
    }
  }
}
