:global(#app) {
    .labelSummory {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        align-items: center;
        width: fit-content;
        // z-index: 2;
        margin-bottom: 10px;
        transition: all 0.2s ease-in-out;

        &:hover {
            .icon {
                opacity: 0.8;
                width: 13px;
                display: block;
                margin-left: 0;
                transition: all 0.2s ease-in-out;
            }
        }
    }
    .labelCreate {
        margin-left: 35px;
    }
    .icon {
        margin: 0;
        overflow: hidden;
        width: 0px;
        word-spacing: 0;
        font-size: 0.92857143em;
        margin-left: -5px;
        transition: all 0.2s ease-in-out;
    }
}
