:global(#app) {
  .buttonFilter {
    background-color: transparent;
    color: #fff;
    font-size: clamp(0.8rem, 1.5vw, 1rem);
    height: fit-content;
    min-height: 38px;
    min-width: 100px;
    position: relative;
    overflow: hidden;

    i {
      display: none;
    }

    &::before {
      position: absolute;
      content: '';
      z-index: -1;
      left: 0;
      top: 0;
      background-color: var(--primary-color-);
      width: 100%;
      height: 100%;
      transition: all 0.2s ease-out;
    }

    &:hover {
      color: var(--primary-color-);
      background-color: #d4e9e2;

      &::before {
        width: 0px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .wrapperToolbar {
      flex-direction: column;
      gap: 5px;
    }
  }
}
