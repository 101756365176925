:global(#app) {
  .activeItem {
    background-color: #2185d0;
    color: #fff;
  }

  .addTime {
    padding: 5px;
    width: fit-content;
  }

  .conflict {
    background-color: #f21e1e;
    border-radius: 3px;
    color: #fff;
  }

  .customTabPane {
    max-height: 310px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  .disabled {
    // pointer-events: none;
    opacity: 0.6;
  }

  .item {
    align-items: center;
    display: flex;
    gap: 4px;
    padding: 0.8rem 0.4rem;

    i {
      width: 0;
      overflow: hidden;
      transition: width 0.2s ease-in-out;
    }

    &:hover {
      i {
        width: 1.18em;
      }
    }
  }

  .time {
    margin-bottom: 3px;
    width: fit-content;
  }

  .wrapper {
    & div > div[class='ui grid'] {
      div[class='eight wide column'] {
        padding-right: 0.5rem;

        @extend .customTabPane;
      }

      div[class='stretched eight wide column'] {
        padding-left: 0.3rem;

        @extend .customTabPane;
      }
    }
  }
}
