:global(#app) {
  --scrollbar-display: 1;

  .closeButton {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 20;
    color: #fff;
    min-height: fit-content;
    background-color: transparent;
    box-shadow: unset;
  }

  // .coverBar {
  //   position: absolute;
  //   right: 10px;
  //   top: 40px;
  //   width: 4px;
  //   height: 100%;
  //   background-color: inherit;
  //   opacity: var(--scrollbar-display);
  //   transition: opacity 0.3s ease-out;
  // }

  .playlist {
    // position: relative;
    // overflow-y: scroll;
    // overflow-x: hidden;
    max-height: 155px;
    margin: 10px;

    &::-webkit-scrollbar {
      width: 4px;
      background-color: inherit;
    }

    &::-webkit-scrollbar-track {
      background: inherit;
      border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      cursor: pointer;
      transition: color 0.2s ease;
      -webkit-transition: color 0.2s ease;
    }
  }

  .titlePlayList {
    background-color: var(--primary-color-);
    color: #fff;
    padding: 10px 0 10px 20px;
    border-bottom-right-radius: 30px;
  }

  .wrapper {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    // width: calc(100% - 90px);
    // z-index: 10;
    display: grid;
    gap: 6px;
    grid-template-columns: 1fr auto;
    margin-bottom: 0;
    padding: 0;
  }

  .wrapPlaylist {
    width: 400px;
    position: relative;
    // margin-block: 14px;
    border-radius: 4px;
    background-color: #ddeae6;
    overflow: hidden;
  }

  @media screen and (max-width: 900px) {
    .wrapper {
      grid-template-columns: 1fr;
    }

    .wrapPlaylist {
      width: 100%;
    }
  }
}
