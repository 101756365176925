:global(#app) {
  .accordion {
    margin-top: 0;
  }

  .active {
    opacity: 1 !important;
  }

  .content {
    overflow-y: auto;
    max-height: 400px;
    padding-right: 6px;
    box-shadow: unset;
    margin-bottom: 8px;
    padding-top: 0;

    &::-webkit-scrollbar {
      width: 5px;
    }
  }

  .field {
    margin-bottom: 8px;
  }

  .title {
    opacity: 0.6;
    width: fit-content;

    &:hover {
      @extend .active;
    }
  }
}
