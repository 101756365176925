:global(#app) {
  .itemDevice {
    align-items: center;
    display: flex;
    gap: 8px;
    max-height: 32px;
  }

  .scrollBox {
    overflow-y: auto;
    max-height: calc(100vh - 250px);

    &::-webkit-scrollbar {
      width: 6px;
    }
  }

  .wrapper {
    min-width: 95vw;
  }

  .wrapperColumn {
    display: grid;
    grid-template-columns: 0.8fr 1fr 1fr;
    gap: 10px;
  }

  @media screen and (max-width: 768px) {
    .wrapperColumn {
      grid-template-columns: 1fr;
    }
  }
}
