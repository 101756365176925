:global(#app) {
  .category {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .content {
    margin-top: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .totalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    & h4 {
      margin-bottom: 0px;
    }

    & p {
      color: var(--primary-color-);
      font-size: 28px;
      font-weight: bold;
    }
  }

  .table {
    margin: 0;
    & tr th {
      border-bottom-width: 2px;
      padding: 6px;
    }

    & tr td {
      border-top: none;
      padding: 6px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 20px;
  }

  .wrapper {
    position: relative;
    height: 100%;
    padding: 12px 30px;
    background: #f9f9f9;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  .wrapperChart {
    width: fit-content;
    margin: auto;
    position: relative;
  }

  .wrapperTable {
  }

  @media screen and (max-width: 599px) {
    .wrapperChart {
      width: 250px;
    }
  }

  @media screen and (max-width: 767px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .wrapper {
      padding: 10px;
    }
  }
}
