:global(#app) {
  .avatarUpload {
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }
  .action {
    border: none;
    border-radius: 0.28571429rem;
    display: inline-block;
    height: 36px;
    overflow: hidden;
    position: relative;
    transition: background 0.3s ease;
    width: 100%;
    padding-left: 20px;
  }

  .actionButton {
    background: transparent;
    color: #6b808c;
    font-weight: normal;
    height: 36px;
    line-height: 24px;
    padding: 6px 12px 6px 0;
    text-align: left;
    text-decoration: underline;
    // width: 100%;
  }

  .centerButton {
    background-color: transparent;
    border-radius: 10px;
    color: #fff;
    display: flex;
    font-weight: 300;
    overflow: hidden;
    padding: 12px 60px;
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-top: 20px;

    &::before {
      background-color: var(--primary-color-);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all 0.2s ease-out;
      width: 100%;
      z-index: -1;
    }

    &:hover {
      background-color: #d4e9e2;
      color: var(--primary-color-);

      &::before {
        width: 0px;
      }
    }
  }

  .descriptionTitle {
    color: var(--primary-color-);
    font-weight: 300;
    font-size: 20px;
  }

  .errorUploadFile {
    display: none;
  }
  .isErrorUploadFile {
    position: absolute;
    display: block;
    top: 10%;
  }
  .formUserSub {
    align-items: center;
    display: flex;
    filter: drop-shadow(0px -1px 10px rgba(0, 0, 0, 0.05));
    justify-content: space-between;
    margin-bottom: 0;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-top: 15px;

    input {
      border-radius: 8px !important;
      border: 1px solid #58dbc2;
      font-size: 16px;
      height: 56px;
      transition: all 0.3s ease-in-out;
      width: 100%;

      &:focus {
        border-color: #1b6241;
        transition: all 0.3s ease-in-out;
      }
    }

    .inputUserFormSetting {
      max-width: 520px;
      width: 60%;

      input[name='username'],
      input[name='email'] {
        pointer-events: none;
      }
    }

    &:nth-child(4) {
      .inputUserFormSetting {
        position: relative;

        input {
          background-color: transparent;
          padding-left: 56px !important;
          z-index: 1;
          -webkit-appearance: none;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        &::before {
          align-items: center;
          background: rgba(245, 245, 247, 0.6);
          border-radius: 8px 0 0 8px;
          border: 0 solid transparent;
          bottom: 0;
          content: "+84";
          display: flex;
          height: 100%;
          justify-content: center;
          left: 0;
          position: absolute;
          width: 51px;
        }
      }
    }

    span {
      font-size: 16px;
      width: 40%;
    }
  }

  .middle .wrapperForm {
    padding-left: 0;
  }

  .sideUpload {
    margin: auto;
    width: 70%;

    span {
      padding-top: 45px;
      padding-left: 0;
      padding-bottom: 10px;
    }

    p {
      color: rgba(0, 0, 0, 0.4);
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 0;
    }
  }

  .selectButtonUpload {
    align-items: center;
    border-radius: 24px;
    border: 2px dashed #58dbc2;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 385px;
    justify-content: center;
    margin: 20px auto 0px auto;
    position: relative;
    transition: all 0.4s ease-in-out;
    width: 70%;

    span {
      color: rgba(0, 0, 0, 0.4);
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 0;
    }
  }

  .sideUploadWrapper {
    align-items: center;
    border-radius: 24px;
    border: 2px dashed #58dbc2;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 385px;
    justify-content: center;
    margin: 20px auto 0px auto;
    position: relative;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    input[type="file"] {
      color: transparent;
      font-size: 300px;
      height: 385px;
      position: absolute;
      width: 100%;
      background: none;
      z-index: 0;
      &::file-selector-button {
        display: none;
      }
    }

    span {
      bottom: 55%;
      color: rgba(0, 0, 0, 0.4);
      font-size: 14px;
      left: 50%;
      line-height: 18px;
      position: absolute;
      z-index: -1;
      transform: translateX(-50%);
    }

    label {
      background: #006c4c;
      border-radius: 9999px;
      border: none;
      color: #d4e9e2;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      height: 36px;
      line-height: 16px;
      outline: none;
      padding: 10px;
      text-align: center;
      z-index: 1;
      transition: all 0.4s ease-in-out;
      &:hover {
        background: #09946a;
        box-shadow: 0px 0px 10px #006c4c;
        transition: all 0.4s ease-in-out;
      }
    }
  }
  .title {
    font-size: 24px;
    margin-bottom: 0;
  }

  .wrapper {
    margin: 10px 10px 0 10px;
  }

  .wrapperForm {
    padding: 20px 0 20px 20px;
  }

  .wrapperUser {
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .WrappFormContent {
    padding: 20px 60px;
  }

  @media (max-width: 1200px) {
    .sideUpload {
      width: 100%;
      span {
        padding-top: 0;
      }
    }

    .selectButtonUpload {
      width: 100%;
    }

    .wrapperForm {
      padding-left: 0;
    }

    .wrapperUser {
      padding-right: 20px;
    }
  }

  @media (max-width: 768px) {
    .formUserSub {
      align-items: start;
      flex-direction: column;
      padding-left: 0;

      span {
        width: fit-content;
      }

      .inputUserFormSetting {
        max-width: unset;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 599px) {
    .wrapperUser {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
