:global(#app) {
  .action {
    background-color: transparent;
    box-shadow: unset;
    border: 1px solid transparent;
    padding: 1px 3px;
    min-height: 16px;

    &:hover {
      border-color: #14804a;
      color: #14804a;
    }
  }

  .button {
    max-height: 38px;
    max-width: 200px;
    margin-left: 10px;
    background-color: #00ad73;
    border: 2px solid #01ce89;
    color: #fff;
    transition: all 0.2s ease-out;

    &:hover {
      opacity: 0.8;
    }
  }

  .delete {
    &:hover {
      border-color: #d12953;
      color: #d12953;
    }
  }

  .marginRight {
    margin-right: 10px;
  }

  .pagination {
    background-color: #f2f8f6;
    padding: 8px;
    text-align: right;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 14px !important;

    a[aria-current='true'] {
      background-color: #00ad73;
      color: #fff;
    }
  }

  .table {
    margin-bottom: 0;
  }

  .tableHeader {
    th {
      background-color: #f2f8f6;
      color: var(--primary-color-);
    }
  }

  .title {
    color: var(--primary-color-);
    font-size: clamp(14px, 4vw, 26px);
  }

  .wrapper {
    margin: 10px 10px 0 10px;
  }

  .wrapperSelect {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
  }

  .wrapperTable {
    overflow-x: auto;
  }

  @media screen and (max-width: 599px) {
    .button {
      margin-left: 0px;
      margin-top: 10px;
    }

    .wrapperSelect {
      flex-direction: column;
    }
  }
}
