:global(#app) {
  .counter {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 4px solid var(--primary-color-);
    display: grid;
    place-items: center;
    margin: 10px auto;
    animation: counter 2s infinite ease-in-out;
    // box-shadow: 0px 0px 10px rgb(37, 158, 37);
    overflow: hidden;
  }

  .hidden {
    height: 0px;
    box-shadow: none;
    border: none;
  }

  @keyframes counter {
    0% {
      box-shadow: 0px 0px 5px rgb(37, 158, 37);
    }

    50% {
      box-shadow: 0px 0px 20px rgb(249, 127, 51);
    }

    100% {
      box-shadow: 0px 0px 5px rgb(37, 158, 37);
    }
  }
}
