:global(#app) {
  $colorIcon: #00c68f;

  .icon {
    cursor: pointer;
    color: $colorIcon;
  }

  .nameItem {
    color: #1e3932 !important;
    text-transform: capitalize;
  }
}
