:global(#app) {
  .action {
    background-color: var(--primary-color-);
    color: #fff;
    min-height: 16px;
    padding: 2px 5px;

    &:hover {
      opacity: 0.8;
    }
  }

  .alignLeft {
    display: flex;
    gap: 14px;
    align-items: center;
  }

  .buttonFilter,
  .buttonAdd {
    min-height: 38px;
    position: relative;
    background-color: transparent;
    color: #fff;
    overflow: hidden;
    justify-self: flex-end;
    font-size: clamp(0.8rem, 1.5vw, 1rem);

    i {
      display: none;
    }

    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--primary-color-);
      width: 100%;
      height: 100%;
      transition: all 0.2s ease-out;
    }

    &:hover {
      color: var(--primary-color-);
      background-color: #d4e9e2;

      &::before {
        width: 0px;
      }
    }
  }

  .delete {
    background-color: #dc362e;
  }

  h2 {
    color: var(--primary-color-);
    font-size: clamp(16px, 2.5vw, 24px);
  }

  .header {
    background-color: rgba(212, 233, 226, 0.4);
  }

  .pagination {
    background-color: rgba(223, 225, 224, 0.4);
    padding: 8px;
    text-align: right;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 14px !important;
  }

  .table {
    // min-width: 700px;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .wrapper {
    margin: 10px 10px 0 10px;
  }

  .wrapperSelect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .wrapperTable {
    overflow-x: auto;
  }

  /* Mobile */
  @media only screen and (max-width: 599px) {
    .alignLeft {
      order: 2;
    }

    .wrapperSelect {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
