:global(#app) {
  .title {
    color: var(--primary-color-);
  }

  .wrapper {
    margin: 10px 30px;
    padding-bottom: 30px;
  }

  @media screen and (max-width: 599px) {
    .wrapper {
      margin: 10px;
    }
  }
}
